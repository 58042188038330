
@import "custom-variables";


.test-score {
  input[type=range] {
    position: relative;
    height: 2.7rem;
    -webkit-appearance: none;
    width: 100%;
    z-index: 2;
    &:before,
    &:after {
      content: '';
      content: "";
      position: absolute;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 50%;
      background-color: #000;
      top: 0.8rem;
      z-index: -1;
    }

    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: #000000;
    border-radius: 0;
    border: 1px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: none;
    border: 1px solid $dark-blue;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background: $dark-blue;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -1.05rem;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #000000;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 0.1rem;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: #000000;
    border-radius: 0;
    border: 1px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: none;
    border: 1px solid $dark-blue;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background: $dark-blue;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 0.1rem;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #000000;
    border: 1px solid #000000;
    border-radius: 0;
    box-shadow: none;
  }
  input[type=range]::-ms-fill-upper {
    background: #000000;
    border: 1px solid #000000;
    border-radius: 0;
    box-shadow: none;
  }
  input[type=range]::-ms-thumb {
    margin-top: 0.1rem;
    box-shadow: none;
    border: 1px solid $dark-blue;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background: $dark-blue;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #000000;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #000000;
  }

  &.is-disabled {
    opacity: 0.6;
  }
}
