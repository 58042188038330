@import "breakpoints";

.toast-top-margin {
  top: 5.4rem;
  left: 50%;
  transform: translateX(-50%);

  @include breakpoint-up-lg {
    top: 10rem;
  }
}
