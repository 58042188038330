@import "custom-variables";
@import "breakpoints";

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: color 0.15s ease-in-out,
              background-color 0.15s ease-in-out,
              border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
  cursor: pointer;
  font-size: 2.1rem;
  text-align: center;
  line-height: 1.17;
  color: $white;
  padding: 2rem 2.45rem;
  font-weight: 500;

  @include breakpoint-up-md {
    font-size: 2.2rem;
    padding: 1.2rem 2.45rem;
  }
}

.btn-contrast {
  background-color: $orange;
  border-color: transparent;
  color: $white;

  &:hover {
    background-color: #ff6431;
  }
}

.btn-primary {
  color: $dark-blue;
  background-color: $white;
  border-color: $dark-blue;
  border-width: 0.3rem;

  &.border-white {
    border: 0.2rem solid $dark-blue;
  }

  &:hover {
    color: $white;
    background-color: $dark-blue;
    text-decoration: none;
  }

  &:disabled {
    border: 0.2rem solid $dark-blue;
    color: $dark-blue;
    opacity: 0.6;

    &:hover {
      background-color: transparent;
      color: $dark-blue;
      cursor: not-allowed;
    }
  }
}

.btn-outline-primary {
  background-color: $gray-contrast;
  border-color: $gray-contrast;
  color: $dark-blue;

  i::before {
    color: $dark-blue;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: transparent;
    border-color: $orange;
    color: $orange;
  }

  &.btn-back {
    background-color: transparent;
    border: none;

    @include breakpoint-up-md {
      background-color: $gray-contrast;
    }
  }
}

.btn-icon {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  padding: 0;
  outline: none;
  line-height: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &-edit {
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    line-height: 0;

  }
}

.btn-arrow {
  background-color: transparent;
  cursor: pointer;

  i::before {
    color: $dark-blue;
  }
}

.btn-reset-file {
  margin-left: 1.2rem;
  position: relative;
  height: 3rem;
  width: 3rem;
  background-color: $white;
  border: 0.2rem solid $dark-blue;
  padding: 0;
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  &::before, &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 0.3rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: $dark-blue;
  }

  &:hover {
    background-color: $dark-blue;

    &::before, &::after {
      background-color: $white;
    }
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
