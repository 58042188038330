@mixin breakpoint-up-sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin breakpoint-up-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin breakpoint-up-med {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin breakpoint-up-lg {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin breakpoint-up-xl {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin breakpoint-up-xxl {
  @media screen and (min-width: 1600px) {
    @content;
  }
}

@mixin breakpoint-down-sm {
  @media screen and (max-width: 575px) {
    @content;
  }
}

@mixin breakpoint-down-md {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin breakpoint-down-lg {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
