
@import "custom-variables";


$lite-orange: #f8c5b4;

.kpi-spatistic-header bs-daterangepicker-container .bs-datepicker.kpi-datepicker bs-calendar-layout .bs-datepicker-body table td:nth-child(2) .in-range.select-end:after  {
  background-color: $orange;
  content: '';
  display: block;
  position: absolute;
  left: 0.7rem;
  top: 0.7rem;
  right: 0.7rem;
  bottom: 0.7rem;
  @media (min-width: 768px) {
    left: 0.3rem;
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
  }
}

.kpi-spatistic-header {

  %date-picker-pseudo-class {
    content: '';
    display: block;
    position: absolute;
    left: 0.7rem;
    top: 0.7rem;
    right: 0.7rem;
    bottom: 0.7rem;
  }

  bs-daterangepicker-container {
    left: -2.5rem!important;
    right: -3.7rem!important;
    top: -9rem!important;
    max-width: 40rem;
    @media (min-width: 400px) {
      transform: translate(0, 6rem) !important;
      left: 1.5rem!important;
      right: initial!important;
      top: 0!important;
    }
    @media (min-width: 670px) {
      max-width: initial;
    }

    .bs-datepicker.kpi-datepicker {
      font-size: 2rem;
      flex-flow: column;
      overflow: hidden;

      @media (min-width: 400px) {
        border-radius: 15px;
        border: 1px solid $blue;
      }
      bs-calendar-layout {
        flex: 0 0 100%;

        .bs-datepicker-head {
          height: auto;
          background-color: $white;

          button {
            color: $black;
            &.next,
            &.previous {
              border: 1px solid $black;
              border-radius: 50%;
              vertical-align: middle;
              span {
                font-size: 3rem;
                line-height: 0;
              }
            }
          }
          &:after {
            display: block;
          }
        }

        .bs-datepicker-body {
          border: 0;
          table {
            th {
              color: $black;
              font-weight: 600;
            }
            td {
              width: 12.5%;
              padding-top: 12.5%;
              position: relative;

              &:nth-child(2) {
                .in-range {
                  &:before {
                    @extend %date-picker-pseudo-class;
                    right: 1.4rem;
                    left: 0;
                    border-radius: 50% 0 0 50%;
                  }
                  &:after {
                    @extend %date-picker-pseudo-class;
                    right: 0;
                    left: 50%;
                    background: $lite-orange;
                    z-index: -1;
                  }
                }
              }
              &:last-child {
                .in-range {
                  &:before {
                    @extend %date-picker-pseudo-class;
                    left: 1.4rem;
                    right: 0;
                    border-radius: 0 50% 50% 0;
                  }
                  &:after {
                    @extend %date-picker-pseudo-class;
                    left: 0;
                    right: 50%;
                    background: $lite-orange;
                    z-index: -1;
                  }
                }
              }

              span {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                &.is-highlighted,
                &.select-start,
                &.select-end {
                  background: none;
                  &:after {
                    @extend %date-picker-pseudo-class;
                    border-radius: 50%;
                    background-color: $orange;
                    z-index: -1;
                    @media (min-width: 768px) {
                      left: 0.3rem;
                      top: 0.3rem;
                      right: 0.3rem;
                      bottom: 0.3rem;
                    }
                  }
                }
                &.select-start {
                  &:not(.is-highlighted) {
                    &:before {
                      @extend %date-picker-pseudo-class;
                      left: 50%;
                      width: 50%;
                      background: $lite-orange;
                    }
                  }
                }
                &.select-end {
                  &:before {
                    @extend %date-picker-pseudo-class;
                    width: 50%;
                    background: $lite-orange;
                  }
                }
                &.in-range {
                  &:before {
                    @extend %date-picker-pseudo-class;
                    left: 0;
                    right: 0;
                    background: $lite-orange;
                  }
                }
              }
              color: black;
              &.is-highlighted:not(.disabled):not(.selected) span,
              span.is-highlighted:not(.disabled):not(.selected) {
                transition: 0s;
                color: $white;
              }
              &.week {
                font-weight: 600;
              }

              span {
                &.selected {
                  color: $white;
                }
              }
              &.disabled {
                color: $gray-300
              }

              .is-other-month,
              &.is-other-month span {
                color: $gray-300
              }
            }
          }
        }

      }

      .bs-datepicker-custom-range {
        background-color: #fff;

        .bs-datepicker-predefined-btns {
          display: flex;
          flex-wrap: wrap;
          border-top: 1px solid silver;
          padding-top: 1.5rem;

          .btn {
            background-color: #fff;
            color: $black;
            font-size: 1.8rem;
            width: auto;

            &.selected, &:active {
              color: $orange;
            }

            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
}


