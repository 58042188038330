
@import "custom-variables";


.ngx-file-drop-custom{
  &__drop-zone {
    min-height: 10rem;
    height: auto;
    background-color: $gray;
    border: none;
    border-radius: 1rem;
    padding: 1rem;
  }
  &__content {
    // max-height: 80rem;
  }
}
