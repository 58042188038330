.recruitment-board-application-container {
  &.empty-column {
    cdk-virtual-scroll-viewport.cdk-virtual-scroll-orientation-vertical {
      .cdk-virtual-scroll-content-wrapper {
        bottom: 0;
        // display flex is needed in order for ng2-dragula container to take full height of the board
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .dragula-container {
        flex: 1 1 100%;
        height: 100%
      }
    }

  }
}
