@import "src/custom-styles/custom-variables";
@import "breakpoints";

.custom-modal-wrapper {
  color: $dark-blue;
  background-color: $white;

  .custom-modal-header {
    color: $dark-blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .close-icon-wrapper {
    display: flex;
    justify-content: flex-end;
    font-size: 3rem;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .custom-modal {
    border-top: 1px solid $dark-blue;

    @include breakpoint-up-md {
      border-top: 1px solid $dark-blue;
    }

    .modal-title {
      font-size: 2.4rem;
      padding-top: 0.5rem;
      color: $dark-blue;

      @include breakpoint-up-md {
        font-size: 3rem;
      }
    }

    .modal-question-wrapper {
      background-color: $white;
      padding: 3rem 1.9rem 1.6rem 1.9rem;
      border-radius: 1rem;
      margin-top: 2rem;
      margin-bottom: 11rem;
      color: $dark-blue;

      @include breakpoint-up-md {
        margin: 0;
        padding: 7.7rem 0 5.7rem 0;
      }

      .modal-question, .modal-notice {
        color: $dark-blue;
      }

      .modal-question {
        margin: 0;
        color: $dark-blue;
      }

      .modal-notice {
        margin-top: 1.2rem;
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: space-between;

      .btn {
        margin-right: 3rem;

        &:last-child {
          margin-right: 0;
        }
      }

      @include breakpoint-up-md {
        justify-content: flex-end;

      }

      .btn-back {
        background-color: transparent;
        border: none;
        margin-left: -2rem;

        span {
          display: none;
        }

        i::before {
          color: $dark-blue;
        }

        @include breakpoint-up-md {
          background-color: $gray-contrast;
          span {
            display: inline;
          }

          i {
            display: none;
          }
        }
      }
    }
  }
}

.candidate-card-modal {
  &.modal-dialog {
    transform: none !important;

    @include breakpoint-up-xl {
      max-width: 150rem;
    }
  }
}

/* style change for confirm modal */
.modal-dialog.template {
  margin: 5.4rem 0 0 0;
  max-width: none;

  @include breakpoint-up-md {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    border: none;
    width: 100%;

    @include breakpoint-up-md {
      background-color: $white;
      max-width: 120rem;
      border-radius: 1rem;
    }
  }
}

.select-responsible-person-wrapper {
  padding-bottom: 0.5rem;
  padding-top: 0.8rem;

  @include breakpoint-up-md {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-responsible-person {
    @include breakpoint-up-md {
      flex: 1;
      margin-left: 1rem;
    }
  }
}

.assign-interview-wrapper {
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;

}

.platform-modal-header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $black;

  .panel-title {
    padding-top: 1rem;
    font-size: 2rem;
    font-weight: 400;

    img {
      margin-right: 1rem;
      max-height: 3rem;
    }
  }
}
