@import "variables";
@import "src/custom-styles/mixins/mixins";
@import "src/custom-styles/breakpoints";

@font-face {
  font-family: "nextu";
  src: url("../../assets/fonts/icons/nextu/nextu.ttf?e1adde") format("truetype"),
       url("../../assets/fonts/icons/nextu/nextu.woff?e1adde") format("woff"),
       url("../../assets/fonts/icons/nextu/nextu.svg?e1adde#nextu") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "nextu" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
  content: $icon-star;
}
.icon-display-check-mark-in-circle {
  &:before {
    content: $icon-display-check-mark-in-circle;
  }
}
.icon-display-suitcase {
  &:before {
    content: $icon-display-suitcase;
  }
}
.icon-check-mark {
  &:before {
    content: $icon-check-mark;
  }
}
.icon-attach {
  &:before {
    content: $icon-attach;
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-linked-in {
  &:before {
    content: $icon-linked-in;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-avatar {
  &:before {
    content: $icon-avatar;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}

.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
  &-outline {
    &:before {
      content: $icon-pencil-outline;
    }
  }

  &-filled {
    &:before {
      content: $icon-pencil-filled;
    }
    color: rgb(218, 215, 196);
  }

  &.orange {
    &:before {
      content: $icon-pencil-outline;
    }
    color: $orange;
  }
}
.icon-bin {
  &:before {
    content: $icon-bin;
  }
}

.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-padlock {
  &:before {
    content: $icon-padlock;
  }
}
.icon-key {
  &:before {
    content: $icon-key;
  }
}
.icon-knock-out {
  &.transparent {
    &:before {
      content: $icon-knockout-outline;
    }
  }

  &.green {
    &:before {
      content: $icon-knockout-filled;
    }
    color: rgb(0, 156, 129);
  }
  &.yellow {
    &:before {
      content: $icon-knockout-filled;
    }
    color: rgb(255, 187, 0);
  }
  &.red {
    &:before {
      content: $icon-knockout-filled;
    }
    color: #b41b3b;
  }
}

.icon-heart {
  &.transparent {
    &:before {
      content: $icon-heart-outline;
    }
  }

  &.green {
    &:before {
      content: $icon-heart-filled;
    }
    color: rgb(0, 156, 129);
  }
  &.yellow {
    &:before {
      content: $icon-heart-filled;
    }
    color: rgb(255, 187, 0);
  }
  &.red {
    &:before {
      content: $icon-heart-filled;
    }
    color: #b41b3b;
  }
}

.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-suitcase {
  &:before {
    content: $icon-suitcase;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-calendar1 {
  &:before {
    content: $icon-calendar1;
  }
}
.icon-ellipses {
  &.transparent {
    &:before {
      content: $icon-ellipses-outline;
    }
  }

  &.green {
    &:before {
      content: $icon-ellipses-filled;
    }
    color: rgb(0, 156, 129);
  }
  &.yellow {
    &:before {
      content: $icon-ellipses-filled;
    }
    color: rgb(255, 187, 0);
  }
  &.red {
    &:before {
      content: $icon-ellipses-filled;
    }
    color: #b41b3b;
  }
}
.icon-arrow-left-in-circle {
  &:before {
    content: $icon-arrow-left-in-circle;
  }
}
.icon-cancel-in-circle {
  &:before {
    content: $icon-cancel-in-circle;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-check-mark-in-circle {
  &:before {
    content: $icon-check-mark-in-circle;
  }
}
.icon-double-chevron-right {
  &:before {
    content: $icon-double-chevron-right;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-three-lines {
  &:before {
    content: $icon-three-lines;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud;
  }
}
.icon-plus-in-circle {
  &:before {
    content: $icon-plus-in-circle;
  }
}
.icon-suitcase-checked {
  &:before {
    content: $icon-suitcase-checked;
  }
}
.icon-paper-plane {
  &:before {
    content: $icon-paper-plane;
  }
}
.icon-three-dots {
  &:before {
    content: $icon-three-dots;
  }
}
.icon-scale {
  &:before {
    content: $icon-scale;
  }
}
.icon-play {
  &.transparent {
    &:before {
      content: $icon-play-outline;
    }
  }

  &.green {
    &:before {
      content: $icon-play-filled;
    }
    color: rgb(0, 156, 129);
  }
  &.yellow {
    &:before {
      content: $icon-play-filled;
    }
    color: rgb(255, 187, 0);
  }
  &.red {
    &:before {
      content: $icon-play-filled;
    }
    color: #b41b3b;
  }
}

.icon-pin-full {
  &:before {
    content: $icon-pin-full;
  }

  cursor: pointer;
}

.icon-pin-empty {
  &:before {
    content: $icon-pin-empty;
  }

  cursor: pointer;
}

.icon-export {
  transform: rotate(90deg);
  &:before {
    content: $icon-export;
    color: rgb(0, 156, 129);

  }
}

.icon-skills {
  font-size: 2.5rem;

  &.transparent {
    &:before {
      content: $icon-skills-outline;
    }
  }

  &.green {
    &:before {
      content: $icon-skills-filled;
    }
    color: rgb(0, 156, 129);
  }
  &.yellow {
    &:before {
      content: $icon-skills-filled;
    }
    color: rgb(255, 187, 0);
  }
  &.red {
    &:before {
      content: $icon-skills-filled;
    }
    color: #b41b3b;
  }
}

.icon-monkey {
  &:before {
    content: $icon-monkey;
  }
}

.icon-full-screen {
  font-size: 3rem;
  cursor: pointer;

  &::before {
    color: #707070;
    content: $icon-full-screen;
  }
}

.icon-drag {
  &:before {
    content: $icon-drag;
  }
}

.icon-warning {
  &:before {
    content: $icon-warning;
  }
}

.icon-trigger {
  font-size: 3rem;

  &:before {
    content: $icon-trigger;
  }
}

.icon-til-tid {
  color: #00459C;
  &:before {
    content: $icon-til-tid;
  }
}

.icon-tick {
  &:before {
    content: $icon-tick;
  }
}

.icon-refapp {
  color: $orange;
  &:before {
    content: $icon-refapp;
  }
}

.icon-block {
  &:before {
    content: $icon-block;
  }
}

.icon-add-image {
  &:before {
    content: $icon-add-image;
  }
}

.icon-eye-hide {
  &:before {
    content: $icon-eye-hide;
  }
}

.icon-eye-view {
  &:before {
    content: $icon-eye-view;
  }
}

.icon-tag {
  &:before {
    content: $icon-tag;
  }
}

.icon-tag-filled {
  &:before {
    content: $icon-tag-filled;
  }
}

.icon-account-lock {
  font-weight: 600;

  &:before {
    content: $icon-account-lock;
  }
}

.icon-sri {
  &:before {
    content: $icon-sri;
  }
}

.icon-save {
  &:before {
    content: $icon-save;
  }
}

.icon-save-fill {
  &:before {
    content: $icon-save-filled;
  }
}

.icon-phone-fill {
  &:before {
    content: $icon-phone-fill;
  }
}

.icon-phone-down-fill {
  &:before {
    content: $icon-phone-down-fill;
  }
}

.icon-mic-on {
  &:before {
    content: $icon-microphone-on;
  }
}

.icon-mic-off {
  &:before {
    content: $icon-microphone-off;
  }
}

.icon-logout {
  &:before {
    content: $icon-logout;
  }
}

.icon-exit {
  &:before {
    content: $icon-exit;
  }
}

.icon-camera-on {
  &:before {
    content: $icon-camera-on;
  }
}

.icon-camera-off {
  &:before {
    content: $icon-camera-off;
  }
}

.icon-hand-raised {
  &:before {
    content: $icon-hand-raised;
  }
}

.icon-sad-emoji {
  &:before {
    content: $icon-sad-emoji;
  }
}

.icon-cancel-in-circle-filled {
  &:before {
    content: $icon-cancel-in-circle-filled
  }
}

.icon-linkedin-in-circle {
  &:before {
    content: $icon-linkedin-in-circle;
  }
}

.icon-alva {
  &:before {
    content: $icon-alva;
  }
}

.icon-alva-fill {
  &.transparent {
    &:before {
      content: $icon-alva-outlined;
    }
  }

  &.green {
    &:before {
      content: $icon-alva-fill;
    }
    color: rgb(0, 156, 129);
  }
}

.icon-share {
  &:before {
    content: $icon-share;
  }
}

.icon-document {
  &:before {
    content: $icon-document;
  }
}

.icon-hide {
  &:before {
    content: $icon-hide;
  }
}

.icon-show {
  &:before {
    content: $icon-show;
  }
}

.icon-stop {
  &:before {
    content: $icon-stop;
  }
}

.icon-redo {
  &:before {
    content: $icon-redo;
  }
}

.icon-down {
  &:before {
    content: $icon-down;
  }
}

.icon-up {
  &:before {
    content: $icon-up;
  }
}
