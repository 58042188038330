@import "src/custom-styles/custom-variables";
@import "breakpoints";

.form-group {
  position: relative;
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;

  @include breakpoint-up-lg {
    padding-bottom: 1.41rem;
  }

  div, span, .form-control , ng-select{
    &:invalid,
    &.is-invalid {
      border-color: $red !important;
      box-shadow: none;

      &~.invalid-feedback {
        display: block;
      }
    }
  }

  .form-control {
    display: block;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: rgba($black, 0.25);
    color: $black;
    border-radius: 1rem;
    font-size: 1.9rem;
    line-height: 1.21;
    padding: 1.5rem;
    box-shadow: none;
    background-color: $white;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    @include breakpoint-up-lg {
      font-size: 2.4rem;
      line-height: 1.25;
    }

    &::placeholder {
      color: $gray-600;
      opacity: 1;
    }

    &:disabled,
    &[readonly] {
      background-color: $gray-200;
      color: $gray-600;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }

    &:focus {
      border-color: $blue;
      box-shadow: none;
      outline: 0;
    }

    &.quiz-input {
      width: 100%;
      border: none;
      border-bottom: 1px solid black;
      border-radius: 0;
      outline: none;
      padding: 0.5rem 3rem 0.5rem 0.5rem;
      margin-bottom: 1rem;
      height: 3rem;
      overflow-y: hidden;
      resize: none;

      @include breakpoint-up-lg {
        height: 4rem;
      }

      &.business-quiz {
        padding: 0.5rem;
        @include breakpoint-up-lg {
          padding: 0.5rem;
        }
      }

      &:focus {
        border-color: $blue;
      }
    }
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }

  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;

    // Undo .form-check-input defaults and add some `margin-right`.
    .form-check-input {
      position: static;
      margin-top: 0;
      margin-right: 0.3125rem;
      margin-left: 0;
    }
  }

  .form-check-label {
    margin-bottom: 0; // Override default `<label>` bottom margin
  }

  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;

    &:disabled ~ .form-check-label {
      color: $gray-600;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #dad7c4 inset !important;
    font-size: 1.9rem;
    line-height: 1.21;

    @include breakpoint-up-lg {
      font-size: 2.4rem;
      line-height: 1.25;
    }
  }

  select.form-control {
    display: block;
    position: relative;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: auto;
    font-size: 1.9rem;
    padding: 1.5rem;
    &:not([size]):not([multiple]) {
      height: auto;
      padding: 1.5rem 2.5rem 1.5rem 1.5rem;
      padding-right: 2.5rem;
      background-image: url('../assets/images/icons/icon-chevron-down.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 1rem);
      background-size: 1.2rem;
    }
    @include breakpoint-up-lg {
      font-size: 2.4rem;
      padding: 1.2rem 3.5rem 1.2rem 1.2rem;
    }
  }


  .borderless & {
    .form-control {
      border: none;
      border-radius: 0;
      border-bottom: 2px solid $black;
      color: $black;
      font-size: 1.9rem;
      font-weight: 400;
      line-height: 1.21;
      padding: 0 0 6.3px 0;
      background-color: transparent;
      outline: none;

      @include breakpoint-up-lg {
        font-size: 2.4rem;
        line-height: 1.17;
      }

      &::-webkit-autofill {
        background-color: transparent;
      }

      &::placeholder {
        background-color: transparent;
        color: #646464;
      }
    }
  }
}

.invalid-feedback {
  display: none;
  margin-bottom: -1.5rem;
  font-size: 1.4rem;
  line-height: 1;
  color: $danger;
  text-align: left;
  width: 100%;
  margin-top: 0.25rem;

  @include breakpoint-up-lg {
    margin-bottom: -1.65rem;
  }

  @include breakpoint-down-lg {
    font-size: 1.25rem;
  }
}

.optional-select-wrapper {
  display: flex;
  align-items: center;

  select.form-control:not([size]):not([multiple]) {
    padding-right: 4.5rem;
  }

  .remove-wrapper {
    position: absolute;
    right: 2.9rem;
    cursor: pointer;

    &:hover {
      color: $red;
    }

    .icon-cancel {
      font-size: 1.1rem;
    }
  }
}

app-forgot-password,
app-create-user {
  .form-group {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255, 1) !important;
    }
  }
}

.sms-form-group {
  .sms-length {
    float: right;
    display: inline-flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
     &.limit-reached {
       color: $red;
     }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

