@import "custom-variables";
@import "src/custom-styles/breakpoints";

.high-contrast {

  // app-confirmation-modal,
  // app-delete-user-modal,
  // app-permissions-modal,
  // app-update-phone-modal,
  // app-universal-modal {
  //     background-color: $white !important;
  // }

  // a {
  //   color: $dark-blue;
  // }

  // .btn-primary {
  //   background-color: $white;
  //   border-color: $dark-blue;
  //   color: $dark-blue;
  //   border-width: 0.3rem;

  //   &:hover {
  //     background-color: $dark-blue;
  //     color: $white;
  //   }

  //   &.border-white {
  //     border: 0.2rem solid $dark-blue !important;
  //   }

  //   &:disabled {
  //     border: 0.2rem solid $dark-blue;
  //     color: $dark-blue;
  //     opacity: 0.6;

  //     &:hover {
  //       background-color: transparent;
  //       color: $dark-blue;
  //       cursor: not-allowed;
  //     }
  //   }
  // }

  // .btn-outline-primary {
  //   background-color: $gray-contrast;
  //   border-color: $gray-contrast;
  //   color: $dark-blue;

  //   i::before {
  //     color: $dark-blue;
  //   }
  // }

  // .btn-arrow {
  //   background-color: transparent;
  //   cursor: pointer;

  //   i::before {
  //     color: $dark-blue !important;
  //   }
  // }

  // .btn-reset-image-icon, .btn-icon-edit, .btn-add-attach, .delete-button {
  //   background-color: $white !important;
  //   border: 0.2rem solid $dark-blue !important;

  //   i:before {
  //     color: $dark-blue;
  //   }

  //   &:hover {
  //     background-color: $dark-blue !important;

  //     i:before {
  //       color: $white;
  //     }
  //   }
  // }

  // .btn-reset-file {
  //   background-color: $white !important;
  //   border: 0.2rem solid $dark-blue !important;

  //   &::before, &::after {
  //     background-color: $dark-blue !important;
  //   }

  //   &:hover {
  //     background-color: $dark-blue !important;

  //     &::before, &::after {
  //       background-color: $white !important;
  //     }
  //   }
  // }

  .button-wrapper {

    //ne mogu nigde da nadjem u projektu
    &.multiple-buttons {
      @include breakpoint-down-md {
        flex-direction: column-reverse;

        button {
          width: 100%;
          margin-bottom: 2rem;
        }
      }
    }

    //dodala kod button primary
    // .btn-back {
    //   background-color: transparent;
    //   border: none;

    //   i::before {
    //     color: $dark-blue !important;
    //   }

    //   @include breakpoint-up-md {
    //     background-color: $gray-contrast;
    //   }
    // }
  }

  // .custom-modal-wrapper {
  //   background-color: $white;
  //   color: $black;

  //   .custom-modal-header {
  //     color: $dark-blue;
  //   }

  //   .custom-modal {
  //     border-color: $dark-blue;

  //     .select-responsible-person-wrapper {
  //       border-color: $dark-blue;
  //       color: $dark-blue;
  //     }

  //     .modal-title,
  //     .modal-question-wrapper .modal-question,
  //     .modal-question-wrapper .modal-notice {
  //       color: $dark-blue;
  //     }

  //     .tabs {
  //       .tab {
  //         color: $beige;
  //       }
  //     }
  //   }
  // }

  //proveri tabs kod business pitanja

  // .trigger:not(.action) {
  //   background-color: $white !important;

  //   .term, i::before {
  //     color: $dark-blue;
  //   }

  //   &.active {
  //     border-color: $dark-blue !important;
  //   }
  // }

  // .search-dialog {
  //   background-color: $white !important;

  //   .search-dialog-title {
  //     color: $dark-blue;
  //   }

  //   .search-input {
  //     border-color: $dark-blue !important;
  //     color: $dark-blue !important;

  //     &::placeholder {
  //       color: $dark-blue;
  //       opacity: 0.8;
  //     }
  //   }

  //   .icon-search::before {
  //     color: $dark-blue;
  //   }

  //   .search-form {
  //     border-color: $dark-blue !important;

  //     input {
  //       color: $dark-blue !important;

  //       &::placeholder {
  //         color: $dark-blue;
  //         opacity: 0.8;
  //       }
  //     }

  //     button i.icon::before {
  //       color: $dark-blue;
  //     }
  //   }

  //   .multiple-search {
  //     border-color: $dark-blue !important;
  //   }

  //   .multiple-search-list-item {
  //     color: $dark-blue !important;
  //   }

  //   .filter-form h2{
  //     color: $dark-blue !important;
  //   }

  //   .colapse-container {
  //     .header span, .header i {
  //       color: $dark-blue;
  //     }
  //   }

  //   .filter-option {

  //     .custom-checkbox {
  //       .label {
  //         color: $dark-blue !important;
  //         font-weight: 500;
  //       }

  //       input[type=radio],
  //       input[type=checkbox] {
  //         & ~ .checkmark::before {
  //           color: $dark-blue !important;
  //         }
  //       }
  //     }
  //   }
  // }

  // .perform-action-dialog {
  //   background-color: $white !important;

  //   .icon-cancel-in-circle {
  //     color: $dark-blue !important;
  //   }

  //   .action-header, h2, .hiring-status, .action-buttons-holder {
  //     color: $dark-blue;
  //     border-color: $dark-blue !important;
  //   }

  //   .hiring-status .hiring-statuses .status-row:hover {
  //     background-color: #e4eff7 !important;
  //   }

  //   .templates-header-container,
  //   .templates-container {
  //     color: $dark-blue;
  //   }

  //   .add-trigger-wrapper {
  //     h2 {
  //       color: $dark-blue !important;
  //     }

  //     label,
  //     .time-divider,
  //     .or-divider  {
  //       color: inherit !important;
  //     }

  //     .trigger-container {
  //       .action-time-of-week,
  //       .action-time {
  //         .form-group .form-control {
  //           border-color: #00000040;
  //         }
  //       }
  //     }
  //   }
  // }

  // .successfully-send-holder {
  //   background-color: $white !important;

  //   p, i::before {
  //     color: $dark-blue !important;
  //   }
  // }

  // .email-date, .message-date, .comment-recruiter-date, .file-link, .event-status {
  //   color: $dark-blue !important;
  //   font-weight: 500;
  // }

  // .module-item {
  //   background-color: $dark-blue !important;

  //   &.active {
  //     border-color: $orange !important;
  //     box-shadow: 0 0 0 0.1rem $orange;
  //   }
  // }

  // .select-all-candidates {
  //   background-color: $dark-blue !important;
  // }

  // input[type=range]::-webkit-slider-thumb {
  //   border: 0.2rem solid $dark-blue;
  //   background: $dark-blue !important;
  // }

  // input[type=range]::-moz-range-thumb {
  //   border: 0.2rem solid $dark-blue;
  //   background: $dark-blue !important;
  // }

  // input[type=range]::-ms-thumb {
  //   border: none;
  //   background: $dark-blue !important;
  // }

  // .slider-track {
  //   background: $dark-blue !important;
  //   &:before,
  //   &:after {
  //     background-color: $dark-blue !important;
  //   }
  // }

  // .range-min-max-info, .range-position-info {
  //   color: $dark-blue;
  // }

  // @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  //   input[type="range"]::-ms-fill-upper {
  //     background-color: $dark-blue !important;
  //   }
  //   input[type="range"]::-ms-fill-lower {
  //     background-color: $dark-blue !important;
  //   }
  //   input[type="range"]::-ms-track {
  //     color: $dark-blue !important;
  //   }
  // }

  // .filter-form .filters .filter-group {
  //   border-top: 1px solid $dark-blue !important;
  // }

  // .export-dialog {
  //   background-color: $white !important;

  //   .platform-option {

  //     .custom-checkbox {
  //       .label {
  //         color: $dark-blue !important;
  //         font-weight: 500;
  //       }

  //       input[type=radio],
  //       input[type=checkbox] {
  //         & ~ .checkmark::before {
  //           color: $dark-blue !important;
  //         }
  //       }
  //     }
  //   }
  // }
}
