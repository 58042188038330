@import "custom-variables";

.tox {
  &.tox-tinymce-aux {
    z-index: 5000 !important;
  }

  &-tinymce {
    border: 1px solid #ccc !important;
    border-radius: 1rem !important;

    .tox-editor-container .tox-editor-header {
      border-bottom: 1px solid #ccc !important;

      .tox-toolbar__primary {
        background: none !important;
      }
    }
  }

  .tox-statusbar {
    height: unset !important;
    border: none !important;
  }
}

.tinymce-editor {
  &.toolbar-position-bottom {
    .tox-editor-container {
      flex-direction: column-reverse !important;

      .tox-editor-header {
        border-top: 1px solid #ccc !important;
        border-bottom: none !important;
      }
    }
  }

  &.is-invalid {
    .tox.tox-tinymce,
    .tox-editor-container .tox-editor-header {
      border-color: $danger !important;
    }

    &~.invalid-feedback {
      display: block;
    }
  }
}

.no-header {
  .tox-editor-header {
    display: none;
  }
}
