@import "custom-variables";
@import "src/custom-styles/breakpoints";

.tooltip {
  position: absolute;
  z-index: 5000;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 2rem;
  word-wrap: break-word;
  opacity: 0;
  border-radius: 2px;
  width: max-content;

  &.show {
    opacity: 1;
  }

  .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: $dark-blue;
    }
  }
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;

  .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;

    &::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: $dark-blue;
    }
  }
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;

  .arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: $dark-blue;
    }
  }
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;

  .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;

    &::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: $dark-blue;
    }
  }
}

.tooltip-inner {
  max-width: 200px;
  padding: 1.25rem 1.5rem;
  color: #fff;
  text-align: left;
  background-color: $dark-blue;
  border-radius: 2rem;
  border: 1px solid $dark-blue;
  white-space: pre-wrap;
}

.custom-tooltip {
  font-size: 2rem;
  .tooltip-inner {
    font-size: 2rem;
  }
}

.kpi-custom-tooltip {
  position: absolute;
  background: rgba(black, 0.7);
  border-radius: 0.45rem;
  color: white;
  z-index: 5;
  padding: 0.4rem;
  display: flex;
  align-items: center;

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  &-square {
    height: 1.5rem;
    width: 1.5rem;
    border: 1px solid white;
    margin-right: 0.5rem;
  }
  &-text {
    margin: 0 0.5rem;
  }
}


.triggers-tooltip {

  .tooltip-inner {
    max-width: 70rem;

    .triggers-tooltip-content {
      & > div:not(:last-child) {
        border-bottom: 1px solid $white;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding-left: 2rem;
      }
    }
  }
}

.personality-test-tooltip {
  .tooltip-inner {
    @include breakpoint-up-lg {
      max-width: 350px;
    }
  }
}
