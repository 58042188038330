
@import "custom-variables";


bs-datepicker-container {
  z-index: 6000;
}

.bs-datepicker {
  font-size: 2rem;
  .bs-datepicker-head {
    height: auto;
    background-color: $white;
    line-height: 0;
    padding-bottom: 20px;
    bs-datepicker-navigation-view {
      display: flex;
      justify-content: space-between;
    }
    button {
      color: $black;
      &.next,
      &.previous {
        border: 1px solid $black;
        border-radius: 50%;
        vertical-align: middle;
        span {
          font-size: 3rem;
          line-height: 0;
        }
      }
    }
  }

  .bs-datepicker-body {
    table {
      th {
        color: $black;
        font-weight: 600;
      }
      td {
        color: black;
        &.is-highlighted:not(.disabled):not(.selected) span,
        span.is-highlighted:not(.disabled):not(.selected) {
          background-color: $orange;
          transition: 0s;
          color: $white;
        }
        &.week {
          font-weight: 600;
        }

        .is-other-month {
          display: none;
          visibility: hidden;
        }

        span {
          &.selected {
            color: $white;
            background-color: $orange;
          }
        }
        &.disabled {
          color: $gray-300
        }

        .is-other-month,
        &.is-other-month span {
          color: $gray-300
        }
      }
    }
  }
}
