@font-face {
  font-family: 'candidte-portal-icons';
  src:  url('fonts/candidte-portal-icons.eot?nvjvx1');
  src:  url('fonts/candidte-portal-icons.eot?nvjvx1#iefix') format('embedded-opentype'),
    url('fonts/candidte-portal-icons.ttf?nvjvx1') format('truetype'),
    url('fonts/candidte-portal-icons.woff?nvjvx1') format('woff'),
    url('fonts/candidte-portal-icons.svg?nvjvx1#candidte-portal-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="candidate-icon-"], [class*=" candidate-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'candidte-portal-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.candidate-icon-heart:before {
  content: "\e900";
}
.candidate-icon-heart-outline:before {
  content: "\e901";
}
.candidate-icon-facebook:before {
  content: "\e902";
}
.candidate-icon-share:before {
  content: "\e903";
}
.candidate-icon-linkedin:before {
  content: "\e904";
}
.candidate-icon-instagram:before {
  content: "\e905";
}
