
@import "custom-variables";
@import "src/custom-styles/mixins/mixins";
@import "src/custom-styles/breakpoints";

.dashboard-section {
  padding: 2rem 3rem;
  background: white;
  border-radius: 5rem;
  border: 0.2rem solid $orange;
  height: 73rem;
  overflow: hidden;

  &.notifications-panel {
    height: 55rem;
    overflow: hidden;


    @media screen and (min-width: 1300px) {
      margin-left: -4rem;
    }
  }

  &.calendar-panel {
    padding: 2rem 0rem;
    margin-bottom: 3rem;
    height: fit-content;

    @media screen and (min-width: 1300px) {
      width: calc(100% - 4rem);
      margin-bottom: 5rem;
      flex: 1;
    }

    h1 {
      margin-right: 3rem;
      margin-left: 3rem
    }
  }

  &.notes-panel {
    margin-top: 3rem;
    height: 45rem;

    @media screen and (min-width: 1300px) {
      width: 97%;
      margin: 5rem 0;
    }
  }
}

.notifications-and-calendar-wrapper {
  @media screen and (min-width: 1300px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    width: 33%;
  }

  bs-datepicker-inline-container {
    width: 100%;
    @include breakpoint-up-md {
      width: 90%;
    }

    .bs-datepicker-container {
      width: 100%;

      bs-days-calendar-view {
        width: 100%;
      }
    }
  }
}

.dashboard-job-card {
  background-color: $white;
  border-radius: 15px;
  padding: 20px 15px;
  margin-bottom: 3rem;

  &.wrap-card {
    border: 2px solid $gray-500;
  }

  .dashboard-all-jobs {
    border-top: 2px solid $dark-blue;

    .dashboard-all-jobs-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .dashboard-all-jobs-title {
        @include heading-type-1-font-sizes;
        margin-bottom: 0;
        color: $dark-blue;
        word-break: break-word;
      }
    }
  }

  .dashboard-all-jobs-info {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    color: $dark-blue;
    align-items: center;

    @include breakpoint-up-md {
      flex-flow: row nowrap;
    }

    .info-parent {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin-top: 0.5rem;
      line-height: 1.18;
      font-weight: 500;
      padding: 0 1rem;
      @include breakpoint-up-md {
        display: block;
        width: auto;
        margin-top: 0;
        flex-grow: 1;
      }

      button {
        width: 100%;

        @include breakpoint-up-md {
          font-size: 1.8rem;
        }

        @include breakpoint-up-lg {
          width: unset;
          font-size: 2rem;
        }
      }

      .info-count {
        font-size: 3rem;
        @include breakpoint-up-md {
          font-size: 4rem;
        }
        @include breakpoint-up-lg {
          font-size: 6rem;
        }
      }

      .info-name {
        width: 45%;
        text-align: left;
        font-size: 1.6rem;
        padding-top: 0.6rem;
        text-transform: capitalize;
        @include breakpoint-up-md {
          width: 100%;
          font-size: 1.8rem;
        }
        @include breakpoint-up-lg {
          font-size: 2rem;
        }
      }
    }
  }
}

.dashboard-modal {
  padding: 3rem 0rem;

  .modal-content {
    display: flex;
    align-content: center;
    min-height: 60rem;
    padding: 3rem;

    app-dashboard-job-list,
    app-notifications-panel {
      height: 100%;
    }
  }

  bs-datepicker-inline-container {
    width: 100%;
    @include breakpoint-up-md {
      width: 90%;
    }

    .bs-datepicker-container {
      width: 100%;

      bs-days-calendar-view {
        width: 100%;
      }
    }
  }
}

.pagination-previous {
  margin-right: 50px;
  cursor: pointer;
}

.pagination-next {
  margin-left: 50px;
  cursor: pointer;
}

.dashboard-pagination-arrow {
  font-size: 1.5rem;

  @include breakpoint-up-lg {
    font-size: 2rem;
  }
}
