@import "../custom-variables";

.toast-container {
  > div {
    background-image: none;
    padding: 0.75rem 1.25rem !important;
    max-width: 50rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03) !important;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15) !important;
      opacity: 1;
      cursor: pointer;
    }
  }

  .toast-error {
    background-color: $red;
    color: $white;
    border-color: $red;
  }

  .toast-success {
    background-color: $green;
    color: $white;
    border-color: $green;
  }

  .toast-info {
    background-color: #D1ECF1;
    color: #36757F;
    border-color: #36757F;
  }
}
