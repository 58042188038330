@import "src/custom-styles/breakpoints";

.chart-title {
  word-break: break-word;
  font-size: 2.4rem;
  border-top: 1px solid $black;
  padding: 1.2rem 0 2rem;

  @include breakpoint-up-md {
    font-size: 3rem;
    padding: 1.6rem 0 3.7rem;
  }
}

.mouse-position-tooltip {
  position: fixed;
  font-size: 0.6rem;
  background: rgba(black, 0.7);
  border-radius: 0.45rem;
  color: white;
  top: 2.5rem;
  z-index: 5;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  &::after {
    content: " ";
    position: absolute;
    top: 120%;
    left: 50%;
    margin-top: -0.7rem;
    border-width: 0.7rem;
    border-style: solid;
    border-color: rgba(black, 0.8) transparent transparent transparent;
  }
  .tooltip-square {
    height: 1.6rem;
    width: 1.6rem;
    border: 1px solid white;
    margin-right: 0.2rem;
  }
  .tooltip-text {
    margin: 0 0.5rem;
  }
}
