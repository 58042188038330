@import "src/custom-styles/custom-variables";
@import "breakpoints";

.ng-select.ng-select-single .ng-select-container {
  height: 44px;
  border: 1px solid rgba($black, 0.25);
  color: $black;
  border-radius: 1rem;
  box-shadow: none;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  display: none;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  height: 100%;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  box-sizing: border-box;
  height: 100%;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: $blue;
  outline: 0.5px $blue;
  box-shadow: none;

  &:invalid,
  &.is-invalid {
    border-color: $red !important;
    box-shadow: none;
  }
}

.ng-select .ng-arrow-wrapper {
  display: none;
}

.ng-select.ng-select-focused > .ng-select-container {
  border-color: $blue;
  outline: 0.5px $blue;
  box-shadow: none;
}

.ng-select.is-invalid.ng-select-single .ng-select-container {
  border: 1px solid $red !important;
  box-shadow: none;
  border-radius: 1rem;
}

.ng-select.is-invalid + .invalid-feedback {
  display: block;
}

.ng-select.ng-select-multiple .ng-select-container {
  border: 1px solid rgba($black, 0.25);
  color: $black;
  border-radius: 1rem;
  box-shadow: none;
  padding-right: 2.5rem;
  background-image: url('../assets/images/icons/icon-chevron-down.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem);
  background-size: 1.2rem;

  .ng-value-container {
    padding-top: 1.06rem;
    padding-bottom: 0.7rem;

    @include breakpoint-up-lg {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      .ng-input {
        padding-bottom: 0;
      }
    }
  }
}

.ng-select {
  &.ng-select-disabled > .ng-select-container {
    background-color: $gray-200;
  }

  &.is-invalid.ng-select-multiple .ng-select-container {
    border: 1px solid $red !important;
    box-shadow: none;
    border-radius: 1rem;
  }

  &.ng-select-multiple .ng-dropdown-panel-items {
    .ng-option {
      .custom-checkbox {
        pointer-events: none;
      }
    }
  }
}
