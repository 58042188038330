
@import "custom-variables";
@import "icons/variables";
@import "src/custom-styles/breakpoints";
@import "../custom-styles/mixins/checked";

.custom-checkbox {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin: 0;
  padding-left: 3rem;
  min-width: 15px;
  min-height: 15px;
  font-size: 1.9rem;

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    height: 15px;
    width: 15px;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border-radius: 50%;

    &::before {
      @include notChecked;

      color: $black;
    }

    &.square {
      border-radius: 3px;
    }
  }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    & ~ .checkmark {
      &::before {
        color: $orange;
        @include checked;
      }
    }

    &.green-checkbox {
      &~.checkmark {
        &::before {
          color: $green;
        }
      }
    }

    &.red-checkbox {
      &~.checkmark {
        &::before {
          color: $red;
        }
      }
    }
  }

  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    & ~ .checkmark {
      outline: 2px $orange;
    }
  }

  &.is-disabled {
    opacity: 0.6;
    cursor: not-allowed;
    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
      & ~ .checkmark {
        &::before {
          color: $gray-800;
        }
      }
    }
  }
}

