$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;


$blue: #7eaabc !default;
$dark-blue: #505f75 !default;
$purple: #805aac !default;
$pink: #cb6b88 !default;
$red: #b41b3b !default;
$orange: #eb5929 !default;
$yellow: #f59f21 !default;
$green: #009c81 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$brown: #b78a5e !default;
$peach: #ffaf84 !default;
$beige: #dad7c4 !default;
$gray: #efefef !default;
$gray-contrast: #E8ECEF !default;
$gray-light: #eaeaea !default;

$primary: $dark-blue !default;
$secondary: $gray-600 !default;
$success: $orange !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
