
@import "custom-variables";


label.custom-toggle-container {
  margin-bottom: 0;
  line-height: 0;
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  input[type="checkbox"] {
    display: none;
  }
  .toggle-switch {
    display:inline-block;
    position: relative;
    height: 2.2rem;
    width: 6rem;
    border: 1px solid $black;
    border-radius: 1rem;
    transition: border-color 100ms ease-in-out;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 2px;
      height: 1.6rem;
      width: 1.6rem;
      background-color: transparent;
      border: 1px solid $black;
      border-radius: 50%;
      transition: left 120ms ease-in-out, background-color 100ms ease-in-out;
      transform: translate(0, -50%);
    }
  }


  input[type="checkbox"]:checked {
    ~ .toggle-switch {
      border-color: $orange;
      &:after {
        right: 2px;
        left: auto;
        background-color: $orange;
        border-color: $orange;
      }
    }
  }

  input[type="checkbox"]:disabled {
    ~ .toggle-switch {
      border-color: $gray-500;
      &:after {
        left: 4rem;
        background-color: $gray-500;
        border-color: $gray-500;
      }
    }
  }
}
