@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  src: url(../assets/fonts/Work_Sans/WorkSans-Regular.ttf);
}

@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  src: url(../assets/fonts/Work_Sans/WorkSans-Medium.ttf);
}

@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  src: url(../assets/fonts/Work_Sans/WorkSans-SemiBold.ttf);
}
