@import "custom-variables";
@import "src/custom-styles/breakpoints";

.question-wrapper {
  margin-bottom: 2.5rem;

  .question {
    position: relative;
    border: 1px solid $black;
    border-radius: 1rem;
    padding: 1.5rem;
    margin-right: 3.5rem;

    .express-interest {
      margin-right: 0;
    }

    &.error {
      border-color: $red;
    }

    &:not(.express-interest):not(.following-question) {
      @include breakpoint-up-md {
        width: calc(100% - 3.5rem);
        &.entier-width {
          width: 100%;
        }
        margin-right: 1rem;
      }

      @include breakpoint-up-lg {
        width: calc(100% - 20rem);
        &.entier-width {
          width: 100%;
        }
      }
    }
  }

  .error-message {
    display: none;
    font-size: 1.5rem;
    color: $red;
  }

  &.error {
    .error-message {
      display: block;
    }
  }
}

.custom-checkbox-questions {
  .label {
    display: none;

    @include breakpoint-up-lg {
      display: inline-block;
      position: absolute;
      left: 3rem;
    }
  }
}

.answers {
  margin-left: 1rem;
}

.answer-container {
  position: relative;
}

.btn-remove-question {
  position: absolute;
  top: 0;
  right: -3.5rem;
  height: 3.2rem;
  width: 3.2rem;
  cursor: pointer;

  @include breakpoint-up-md {
    right: -5rem;
  }

  .icon-bin {
    font-size: 2.5rem;

    @include breakpoint-up-md {
      font-size: 3rem;
    }
  }
}

.answer-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $black;
  margin-bottom: 2rem;
  gap: 1rem;

  &.safari-or-ios-device {
    gap: 1.4rem;
  }

  .form-group {
    flex: 1;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    width: 100%;
  }
  .quiz-input {
    border-bottom: none !important;
    margin-bottom: 0 !important;
  }
  .btn-remove-answer {
    cursor: pointer;
    .icon-cancel {
      font-size: 1.6rem;
    }
  }
}
