@import "../icons/variables";
@import "../custom-variables";

@mixin checked {
  content: $icon-tick;
  font-family: "nextu" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  line-height: 15px;
  font-size: 15px;
  background-image: radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 52%, rgba(255,255,255,0) 100%);
}

@mixin notChecked {
  content: $icon-circle-outline;
  border-radius: 50%;
  font-family: "nextu" !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  line-height: 15px;
  font-size: 15px;
}

@mixin checkboxNumber {
  &::before {
    @include notChecked;
    color: $black;
  }

  &.checkmark-number {
    &::before {
      content: attr(data-content);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 12px;
      line-height: 2rem;
      background: $green;
      height: 15px;
      width: 15px;
      font-weight: 500;
      font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    }
  }
}
