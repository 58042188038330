$icomoon-font-family: "nextu" !default;
$icomoon-font-path: "fonts" !default;

$icon-star: "\e900";
$icon-check-mark: "\e901";
$icon-attach: "\e902";
$icon-cancel: "\e903";
$icon-arrow-right: "\e904";
$icon-linked-in: "\e905";
$icon-instagram: "\e906";
$icon-info: "\e908";
$icon-facebook: "\e907";
$icon-avatar: "\e909";
$icon-link: "\e90a";
$icon-calendar: "\e90b";
$icon-bin: "\e911";
$icon-delete: "\e912";
$icon-arrow-left: "\e913";
$icon-padlock: "\e914";
$icon-key: "\e915";
$icon-knock-out: "\e916";
$icon-heart: "\e918";
$icon-mail: "\e919";
$icon-location: "\e91a";
$icon-suitcase: "\e91b";
$icon-clock: "\e91c";
$icon-phone: "\e91d";
$icon-calendar1: "\e91e";
$icon-ellipses: "\e91f";
$icon-arrow-left-in-circle: "\e920";
$icon-cancel-in-circle: "\e921";
$icon-search: "\e922";
$icon-check-mark-in-circle: "\e923";
$icon-double-chevron-right: "\e924";
$icon-chevron-right: "\e925";
$icon-three-lines: "\e926";
$icon-plus: "\e927";
$icon-cloud: "\e928";
$icon-plus-in-circle: "\e929";
$icon-suitcase-checked: "\e92a";
$icon-paper-plane: "\e92b";
$icon-three-dots: "\e92c";
$icon-scale: "\e92d";
$icon-play: "\e941";
$icon-display-suitcase: "\e942";
$icon-display-check-mark-in-circle: "\e943";
$icon-pin-full: "\e944";
$icon-pin-empty: "\e945";
$icon-export: "\e946";
$icon-skills: "\e947";
$icon-full-screen: "\e948";
$icon-monkey: "\e949";
$icon-drag: "\e94a";
$icon-warning: "\e94b";
$icon-trigger: "\e94c";
$icon-tick: "\e94d";
$icon-refapp: "\e94e";
$icon-til-tid: "\e94f";
$icon-skills-outline: "\e950";
$icon-skills-filled: "\e951";
$icon-block: "\e952";
$icon-add-image: "\e953";
$icon-eye-hide: "\e954";
$icon-eye-view: "\e955";
$icon-tag: "\e956";
$icon-tag-filled: "\e957";
$icon-account-lock:"\e958";
$icon-sri:"\e959";
$icon-circle-outline:"\e936";
$icon-circle-filled:"\e936";
$icon-heart-outline:"\e92f";
$icon-heart-filled:"\e92e";
$icon-knockout-outline:"\e931";
$icon-knockout-filled:"\e930";
$icon-play-outline:"\e934";
$icon-play-filled:"\e933";
$icon-ellipses-outline:"\e90f";
$icon-ellipses-filled:"\e90e";
$icon-pencil-outline:"\e910";
$icon-pencil-filled:"\e932";
$icon-pencil:"\e937";
$icon-save:"\e938";
$icon-phone-fill:"\e939";
$icon-phone-down-fill:"\e93a";
$icon-microphone-on:"\e93b";
$icon-microphone-off:"\e93c";
$icon-logout:"\e93d";
$icon-exit:"\e93e";
$icon-camera-on:"\e93f";
$icon-camera-off:"\e940";
$icon-save-filled:"\e95a";
$icon-hand-raised: "\e95b";
$icon-sad-emoji: "\e95c";
$icon-cancel-in-circle-filled: "\e95e";
$icon-linkedin-in-circle: "\e95f";
$icon-share: "\e960";
$icon-alva: "\e961";
$icon-alva-fill: "\e962";
$icon-alva-outlined: "\e963";
$icon-document: "\e964";
$icon-hide: "\e965";
$icon-show: "\e966";
$icon-stop: "\e967";
$icon-redo: "\e968";
$icon-down: "\e969";
$icon-up: "\e96a";
