
@import "../custom-variables";

@import "src/custom-styles/breakpoints";

@mixin heading-type-1-font-sizes {
  font-size: 2rem;
  line-height: 1.7;
  font-weight: 400;

  @include breakpoint-up-lg {
    font-size: 2.2rem;
    line-height: 1.6;
    font-weight: 400;
  }
}

@mixin heading-type-2-font-sizes {
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 600;

  @include breakpoint-up-lg {
    font-size: 1.1875rem;
    line-height: 1.2;
    font-weight: 600;
  }
}

@mixin heading-type-3-font-sizes {
  font-size: 2.3rem;
  line-height: 1.17;
  font-weight: 400;
}
